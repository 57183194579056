/* eslint-disable */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { UPDATE_BOOKING } from 'actions/booking';
import {
  PAGES,
} from 'config';
import { BookingLayout } from 'containers';
import { BookingHeader, ExtraTravellerDetailsFieldset } from 'layouts';
import { mapStateToProps } from 'utilities';
import { BookingToolbar } from 'components';

class BookingsExtraDetailsPage extends PureComponent {
  static mergeTravellerData(travellers, formData) {
    return travellers
      .map((traveller, index) => {
        const PASSPORT_ISSUE_DAY = formData.getAll('TravellerPassportIssueDay')[index];
        const PASSPORT_ISSUE_MONTH = formData.getAll('TravellerPassportIssueMonth')[index];
        const PASSPORT_ISSUE_YEAR = formData.getAll('TravellerPassportIssueYear')[index];

        const PASSPORT_EXPIRY_DAY = formData.getAll('TravellerPassportExpiryDay')[index];
        const PASSPORT_EXPIRY_MONTH = formData.getAll('TravellerPassportExpiryMonth')[index];
        const PASSPORT_EXPIRY_YEAR = formData.getAll('TravellerPassportExpiryYear')[index];
        return {
          ...traveller,
          // overwrite with form values
          passport_date_of_issue: `${PASSPORT_ISSUE_YEAR}-${PASSPORT_ISSUE_MONTH}-${PASSPORT_ISSUE_DAY}`,
          passport_date_of_expiry: `${PASSPORT_EXPIRY_YEAR}-${PASSPORT_EXPIRY_MONTH}-${PASSPORT_EXPIRY_DAY}`,
          passport_number: formData.getAll('TravellerPassportNumber')[index],
          medical_condition: formData.getAll('TravellerMedicalConditions')[index],
          allergies: formData.getAll('TravellerDietaryRequirements')[index],
          emergency_contact_name: formData.getAll('EmergencyContactName')[index],
          emergency_contact_relationship: formData.getAll('EmergencyContactRelationship')[index],
          emergency_contact_number: formData.getAll('EmergencyContactNumber')[index],
          insurance_name: formData.getAll('InsuranceName')[index],
          insurance_policy_number: formData.getAll('InsurancePolicyNumber')[index],
          insurance_contact_number: formData.getAll('InsuranceContactNumber')[index],
        };
      });
  }
  
  constructor(props) {
    super(props);

    this.state = {
      formAction: 'save',
      saving: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSave = this.handleSave.bind(this);

    if (!props.user.authenticated || props.user.session.is_guest) {
        props.dispatch({
          type: 'MESSAGES_ADD',
          payload: {
              id: 'make-payment',
              type: 'error',
              content: 'Please sign in to access this page',
          },
        });

        if (typeof window === 'undefined') return;

        // get booking ID from URL
        const PARSED = queryString.parse(window.location.search);
        const RETURN_URL = encodeURIComponent(`${PAGES.EXTRA_DETAILS.PATH}?bookingId=${PARSED.bookingId}&tour=${PARSED.tour}`);
        navigate(`${PAGES.SIGN_IN.PATH}?return=${RETURN_URL}`);
        return null;
    }
  }

  handleSave() {
    this.setState({
      formAction: 'save',
    });
    // let form submit...
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { user, booking, dispatch } = this.props;
    const FORM = e.currentTarget;
    const FORM_DATA = new FormData(FORM);
    if (!FORM.checkValidity()) return;

    this.setState({
      saving: true,
    }, async () => {
      const TRAVELLERS = BookingsExtraDetailsPage.mergeTravellerData(booking.travellers, FORM_DATA);
      // 1. Update booking
      const UPDATE = await UPDATE_BOOKING(booking.currentBooking.id, user.session.token, user.session.email, {
        travellers: TRAVELLERS
      });

      dispatch({
        type: 'MESSAGES_ADD',
        payload: {
          id: 'update-booking',
          type: UPDATE.success ? 'success' : 'error',
          content: UPDATE.success ? 'Your booking has been updated' : 'Failed to update your booking.',
        },
      });

      setTimeout(() => {
        dispatch({
          type: 'MESSAGES_REMOVE',
          payload: 'update-booking',
        });
      }, 5000);

      if (!UPDATE.success) return;

      this.setState({
        saving: false,
      });
    });
  }

  getExtraDetails() {
      const { booking } = this.props;
      const { currentBooking, travellers } = booking;
      const {
        saving,
      } = this.state;
      return (
        <div>
          <div className="u-card">
            {!currentBooking || !travellers
              ? <h4 className="c-heading c-heading--h4">Loading...</h4>
              : (
                <form className="l-traveller-details-form" action="" onSubmit={this.handleFormSubmit}>
                  {
                  travellers.map((traveller, index) => (
                    <ExtraTravellerDetailsFieldset
                      key={traveller.id}
                      title={index === 0 ? 'Primary Traveller details' : null}
                      traveller={traveller}
                      travellerNumber={index + 1}
                      lastName={traveller.last_name}
                      travellerTitle={traveller.title}
                    />
                  ))
                }
                <BookingToolbar saving={saving}>
                  <button onClick={this.handleSave} type="submit" value="Save" className="c-button c-button--border">Save</button>
                </BookingToolbar>
                </form>
              )
            }
          </div>
        </div>
      );
    }

  render() {
      const { user} = this.props;
      return (
        <BookingLayout page="bookings/extra-details">
          <div className="l-two-col">
            <BookingHeader
              title="OUTSTANDING DOCUMENTATION"
              subTitle="There is some outstanding documentation we need from you prior to departure"
            />
            {
            user.session.is_guest
              ? <h4>You are not authorised to access this page.</h4>
              : this.getExtraDetails()
            }
          </div>
        </BookingLayout>
      );
    }
}

BookingsExtraDetailsPage.propTypes = {
  user: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BookingsExtraDetailsPage);